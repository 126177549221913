@tailwind base;

@tailwind components;

@tailwind utilities;

/* add fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

/* we need to manually add the fonts due to an issue with the naming scheme */
@font-face {
  font-family: 'Press Start 2P';
  src: url('../../public/static/fonts/PressStart2P-Regular.ttf');
}

@font-face {
  font-family: 'Silkscreen';
  src: url('../../public/static/fonts/Silkscreen-Regular.ttf');
}

@font-face {
  font-family: 'Witch One Font';
  src: url('../../public/static/fonts/Witch-One.ttf');
}


body {
  background-color: rgb(36, 0, 70);
}

@layer base {
  html {
    font-family: 'Witch One Font';
  }
}
